import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from 'context/Context';
import Flex from 'components/common/Flex';

import * as Moment from 'moment';
import { GoogleMap, Marker } from '@react-google-maps/api';
import moment from 'moment/moment';
import {
  ExportEventsFromHome,
  ShowEventCalendarLinks
} from '../../../../../services/Event/EventService';
import { toast } from 'react-toastify';

const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ms-n1 me-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon
      icon={icon}
      transform={transform ?? ''}
      className="text-primary fa-stack-1x"
      inverse
    />
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
  <Flex className="mt-3">
    {getCircleStackIcon(icon)}
    <div
      className="flex-1"
      style={{
        maxWidth: '90%',
        wordWrap: 'break-word'
      }}
    >
      <h6 style={{ fontFamily: 'Open sans' }}>{heading}</h6>
      {children || (
        <p className="mb-0 text-justify">
          {' '}
          <span
            className="smaller-p mb-0"
            dangerouslySetInnerHTML={{
              __html: content.replace(/\n/g, '<br />')
            }}
          ></span>
        </p>
      )}
    </div>
  </Flex>
);

const CalendarEventModal = ({
  setIsOpenEventModal,
  isOpenEventModal,
  modalEventContent
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const handleClose = () => {
    setIsOpenEventModal(!isOpenEventModal);
  };

  const { title } = isOpenEventModal && modalEventContent.event;

  const {
    organizerId,
    description,
    location_lng,
    location_lat,
    location,
    organizer,
    link,
    schedules,
    originalEvent
  } = isOpenEventModal && modalEventContent.event.extendedProps;

  const position = {
    lat: location_lat,
    lng: location_lng
  };

  const containerStyle = {
    width: '90%',
    height: '250px'
  };
  const center = {
    lat: location_lat,
    lng: location_lng
  };
  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true
  };

  const [eventLinks, setEventLinks] = useState([]);

  useEffect(async () => {
    if (modalEventContent?.event?.id) {
      const response = await ShowEventCalendarLinks(
        modalEventContent?.event?.id
      );
      if (response.success === true) {
        setEventLinks(response.data);
      } else {
        setEventLinks([]);
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    }
  }, [modalEventContent]);

  return (
    <>
      {originalEvent && (
        <Modal
          show={isOpenEventModal}
          onHide={handleClose}
          contentClassName="border"
          centered
        >
          <Modal.Header
            closeButton
            closeVariant={isDark ? 'white' : undefined}
            className="bg-light px-card border-bottom-0"
          >
            <Modal.Title>
              <h5 className="mb-0" style={{ fontFamily: 'Open sans' }}>
                {title}
              </h5>
              {organizer && (
                <p
                  className="mb-0 fs--1 mt-1 fw-normal"
                  style={{ fontFamily: '"Open Sans", sans-serif' }}
                >
                  par{' '}
                  {organizerId === 136 ? (
                    'Tred Union'
                  ) : (
                    <a href={`/adherents/${organizerId}`}>{organizer}</a>
                  )}
                </p>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-card pb-card pt-1 fs--1">
            {originalEvent.banner_picture_url && (
              <img
                heading="Image de bannière"
                src={originalEvent.banner_picture_url}
                className="w-100"
              />
            )}
            {description && (
              <EventModalMediaContent
                icon="align-left"
                heading="Description"
                content={description}
              />
            )}
            <EventModalMediaContent
              icon="calendar-check"
              heading="Date et Heure"
            >
              <p className="mb-1">
                {originalEvent.start_is_all_day
                  ? `du
            ${Moment(originalEvent.start_date)
              .local()
              .format('DD/MM/YYYY')} ${moment(
                      originalEvent.start_time,
                      'HH:mm:ss'
                    ).format('HH:mm')} au
            ${Moment(originalEvent.end_date)
              .local()
              .format('DD/MM/YYYY')} ${moment(
                      originalEvent.end_time,
                      'HH:mm:ss'
                    ).format('HH:mm')}`
                  : `le ${Moment(originalEvent.start_date)
                      .local()
                      .format('DD/MM/YYYY')} de ${moment(
                      originalEvent.start_time,
                      'HH:mm:ss'
                    ).format('HH:mm')} à ${moment(
                      originalEvent.end_time,
                      'HH:mm:ss'
                    ).format('HH:mm')}`}
              </p>
              {eventLinks?.length > 0 && (
                <div>
                  <h6 className="mt-3 mb-2">
                    <b>Ajouter à mon calendrier : </b>
                    <span className="d-block mt-2" />
                    <a target="_blank" rel="noreferrer" href={eventLinks[0]}>
                      Google
                    </a>{' '}
                    |{' '}
                    <a target="_blank" rel="noreferrer" href={eventLinks[1]}>
                      Yahoo
                    </a>{' '}
                    |{' '}
                    <a target="_blank" rel="noreferrer" href={eventLinks[2]}>
                      Outlook
                    </a>{' '}
                    |{' '}
                    <a
                      style={{ cursor: 'pointer', color: '#2c7be5' }}
                      onClick={() => {
                        console.log(eventLinks[3]);
                        const decodedData = atob(eventLinks[3].split(',')[1]); // Décoder la partie après "base64,"
                        const blob = new Blob([decodedData], {
                          type: 'text/calendar;charset=utf-8'
                        });

                        // Générer une URL temporaire pour le téléchargement
                        const url = URL.createObjectURL(blob);

                        // Créer un lien de téléchargement
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'event.ics';
                        a.click();

                        // Libérer la mémoire utilisée par l'URL temporaire
                        URL.revokeObjectURL(url);
                      }}
                    >
                      Sur mon ordinateur
                    </a>
                  </h6>
                </div>
              )}
            </EventModalMediaContent>
            {originalEvent.attachments?.length > 0 && (
              <EventModalMediaContent icon="file" heading="Fichiers joints">
                <ul className="list-unstyled">
                  {originalEvent.attachments.map((attachment, index) => (
                    <li key={index}>
                      <a
                        href={attachment.file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        pièce jointe {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              </EventModalMediaContent>
            )}
            {link !== null ? (
              <EventModalMediaContent
                icon="share-alt"
                heading="Lien de l'événement en ligne"
              >
                <a target="_blank" rel="noopener noreferrer" href={link}>
                  {link}
                </a>{' '}
                {originalEvent.link_code && (
                  <>
                    <br />
                    Code d'accès : {originalEvent.link_code}
                  </>
                )}
                <div className="d-block mb-1"></div>
              </EventModalMediaContent>
            ) : (
              <></>
            )}{' '}
            {location !== null ? (
              <EventModalMediaContent
                icon="map-marker-alt"
                style={{ color: 'red' }}
                heading="Lieu"
              >
                <div
                  className="mb-1"
                  dangerouslySetInnerHTML={{ __html: location }}
                />
                <div>{originalEvent.location_additional_info}</div>
                <div className="d-block mt-2" />
                <GoogleMap
                  center={center}
                  zoom={12}
                  mapContainerStyle={containerStyle}
                  options={mapOptions}
                >
                  <></>
                  <Marker position={position} />
                </GoogleMap>
              </EventModalMediaContent>
            ) : (
              <></>
            )}
            {schedules && (
              <EventModalMediaContent icon="clock" heading="Schedule">
                <ul className="list-unstyled timeline mb-0">
                  {schedules.map((schedule, index) => (
                    <li key={index}>{schedule.title}</li>
                  ))}
                </ul>
              </EventModalMediaContent>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

EventModalMediaContent.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.node
};

CalendarEventModal.propTypes = {
  setIsOpenEventModal: PropTypes.func.isRequired,
  isOpenEventModal: PropTypes.bool.isRequired,
  modalEventContent: PropTypes.object.isRequired
};

export default CalendarEventModal;
