import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'elearning/medias';

export async function PostMedia(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function DeleteMedia(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
