import ModelService from './../ModelService';
const modelService = new ModelService();

const model = 'elearning/requests';

export async function IndexRequest() {
  return modelService.IndexModel(model);
}

export async function IndexRequestHistory() {
  return modelService.IndexModel(`${model}?context=history`);
}

export async function PostRequest(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutRequest(id) {
  return modelService.PutModel(`${model}/${id}`, {});
}

export async function PauseRequest(id) {
  return modelService.PutModel(`${model}/${id}/mark-as-paused`, {});
}

export async function DeleteRequest(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
