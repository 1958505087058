import ModelService from '../ModelService';
const modelService = new ModelService();

const model = 'events';

export async function IndexEventHomepage() {
  return modelService.IndexModel(`${model}/index-home`);
}

export async function IndexEventMember() {
  return modelService.IndexModel(`${model}?context=user.home`);
}

export async function IndexEventAdmin(selectedDate) {
  console.log('selectedDate', selectedDate);
  return modelService.IndexModel(
    `${model}?context=admin.home&selected_date=${selectedDate}`
  );
}

export async function IndexEventSelect(withFuture = false) {
  return modelService.IndexModel(
    `${model}?context=admin.select${withFuture ? '&with_future=true' : ''}`
  );
}

export async function ShowEvent(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function ShowEventCalendarLinks(id) {
  return modelService.ShowModel(`${model}/${id}/get-calendar-links`);
}

export async function PostEvent(formData) {
  return modelService.PostModel(`${model}`, formData);
}

export async function PutEvent(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function PutEventManagePoints(id, formData) {
  return modelService.PutModel(
    `${model}/${id}?context=admin.validatepoints`,
    formData
  );
}

export async function ManageUserToEvent(id, formData) {
  return modelService.PutModel(`${model}/${id}`, formData);
}

export async function DeleteEvent(id) {
  return modelService.DeleteModel(`${model}/${id}?context=admin.manage`);
}

export async function DeclineEvent(id) {
  return modelService.DeleteModel(`${model}/${id}?context=admin.decline`);
}

export async function ExportEvents(year, formSearch) {
  return modelService.IndexModel(
    `${model}/export?context=admin.export&year=${year}&${formSearch}`
  );
}

export async function ExportEventsFromHome(year, formSearch) {
  return modelService.IndexModel(`${model}/export?year=${year}&${formSearch}`);
}

export async function SendConfirmation(id, formData) {
  return modelService.PutModel(
    `${model}/${id}/send-confirmation-to-members`,
    formData
  );
}

export async function SendEventNotification(id, formData) {
  return modelService.PutModel(`${model}/${id}/send-notification`, formData);
}
