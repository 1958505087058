import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import AppContext from 'context/Context';
import Homepage from '../components/tredunion/frontpages/home/Homepage';
import CompanyShow from '../components/tredunion/frontpages/companies/Show';
import PostShow from '../components/tredunion/frontpages/posts/Show';
import PostIndex from '../components/tredunion/frontpages/posts';
import VisMaRoute from '../components/tredunion/frontpages/vismaroute';
import LTR from '../components/tredunion/frontpages/staticpages/LTR';
import LMTR from '../components/tredunion/frontpages/staticpages/LMTR';
import MentionsLegales from '../components/tredunion/frontpages/staticpages/legal';
import RGPD from '../components/tredunion/frontpages/staticpages/rgpd';
import LoginTred from '../components/tredunion/authentication/Login';
import ForgetPassword from '../components/tredunion/authentication/ForgetPassword';
import PasswordReset from '../components/tredunion/authentication/PasswordReset';
import AuthPartnerLayout from './AuthPartnerLayout';
import HomePartners from '../components/tredunion/authpages/partners/home';
import AuthMembreLayout from './AuthMembreLayout';
import HomeMembers from '../components/tredunion/authpages/members/home';
import TransfertFichier from '../components/tredunion/authpages/members/TransfertFichier';
import TrouverUnMembre from '../components/tredunion/authpages/members/TrouverUnMembre';
import TredRessources from '../components/tredunion/authpages/members/TredRessources';
import Visios from '../components/tredunion/authpages/members/Visios';
import Factures from '../components/tredunion/authpages/members/Factures';
import CodesAchats from '../components/tredunion/authpages/members/CodesAchats';
import InfosPerso from '../components/tredunion/authpages/InfosPerso';
import Elearning from '../components/tredunion/authpages/members/Elearning';
import ElearningCourse from '../components/tredunion/authpages/members/ElearningCourse';
import AuthMembreElearningLayout from './AuthMembreElearningLayout';
import ElearningCongratulation from '../components/tredunion/authpages/members/ElearningCongratulation';
import AuthMembreElearningCongratulationLayout from './AuthMembreElearningCongratulationLayout';
import AdminLayout from './AuthAdminLayout';
import IndexCompanies from '../components/tredunion/authpages/admins/companies';
import ManageCompanies from '../components/tredunion/authpages/admins/companies/manage';
import IndexPartners from '../components/tredunion/authpages/admins/partners';
import ManagePartners from '../components/tredunion/authpages/admins/partners/manage';
import ManagePosts from '../components/tredunion/authpages/admins/posts/manage';
import IndexSliders from '../components/tredunion/authpages/admins/sliders';
import ManageSlides from '../components/tredunion/authpages/admins/sliders/manage';
import IndexVisios from '../components/tredunion/authpages/admins/visios';
import ManageVisios from '../components/tredunion/authpages/admins/visios/manage';
import IndexVisMaRoute from '../components/tredunion/authpages/admins/vis-ma-route';
import ManageVisMaRouteContent from '../components/tredunion/authpages/admins/vis-ma-route/manage';
import IndexElearningCourses from '../components/tredunion/authpages/admins/elearning/courses';
import IndexCourseRequestsHistory from '../components/tredunion/authpages/admins/elearning/requests/IndexCourseRequestsHistory';
import ManageCourses from '../components/tredunion/authpages/admins/elearning/courses/manage';
import ShowEvaluation from '../components/tredunion/authpages/admins/elearning/evaluations/show';
import IndexCourseLogs from '../components/tredunion/authpages/admins/elearning/logs';
import IndexCourseRequests from '../components/tredunion/authpages/admins/elearning/requests';
import IndexSharedFiles from '../components/tredunion/authpages/admins/sharedFiles';
import ShowSharedFile from '../components/tredunion/frontpages/sharedFiles/show';
import IndexUsers from '../components/tredunion/authpages/admins/users';
import ManageUsers from '../components/tredunion/authpages/admins/users/manage';
import IndexLogs from '../components/tredunion/authpages/admins/logs';
import IndexUploads from '../components/tredunion/authpages/admins/upload';
import IndexInvoices from '../components/tredunion/authpages/admins/invoices';
import ManageInvoices from '../components/tredunion/authpages/admins/invoices/manage';
import IndexPurchaseCodes from '../components/tredunion/authpages/admins/purchasecodes';
import IndexResources from '../components/tredunion/authpages/admins/resources';
import ManageResource from '../components/tredunion/authpages/admins/resources/manage';
import IndexResourcesCategories from '../components/tredunion/authpages/admins/resourcesCategories';
import OldResourceDl from '../components/tredunion/frontpages/oldResourceDl/show';
import IndexPosts from '../components/tredunion/authpages/admins/posts';
import TelechargerAnnuaire from '../components/tredunion/authpages/members/TelechargerAnnuaire';
import IndexExportsExcel from '../components/tredunion/authpages/admins/companies/exportsExcel';
import DetailsEvents from '../components/tredunion/authpages/admins/events/Show';
import ManageEvents from '../components/tredunion/authpages/admins/events/Manage';
import IndexEvents from '../components/tredunion/authpages/admins/events';
import ManageSurveys from '../components/tredunion/authpages/admins/surveys/Manage';
import SurveyForm from '../components/tredunion/frontpages/surveys/SurveyForm';
import IndexSurvey from '../components/tredunion/authpages/admins/surveys';
import ShowSurvey from '../components/tredunion/authpages/admins/surveys/Show';
import ManagePurchaseDeclaration from '../components/tredunion/authpages/admins/surveys/types/purchaseDeclarations/Manage';
import HomeLink from '../components/tredunion/frontpages/surveys/homeLink';
import IndexTradePress from '../components/tredunion/authpages/admins/tradePress';
import ManageTradePress from '../components/tredunion/authpages/admins/tradePress/manage';
import IndexTradePressMember from '../components/tredunion/authpages/members/IndexTradePressMember';
import ShowTradePressMember from '../components/tredunion/authpages/members/ShowTradePressMember';
import IndexSurveyParticipationForm from '../components/tredunion/authpages/admins/surveys/indexSurveyParticipationForm';
import ShowSurveyparticipationForm from '../components/tredunion/authpages/admins/surveys/ShowSurveyparticipationForm';
import ManageSurveyParticipationForm from '../components/tredunion/authpages/admins/surveys/ManageSurveyParticipationForm';
import IndexBilansPedagogiques from '../components/tredunion/authpages/admins/bilansPedagogiques/indexElearning';
import IndexBilansPedagogiquesElearning from '../components/tredunion/authpages/admins/bilansPedagogiques/indexElearning';
import IndexBilansPedagogiquesEvent from '../components/tredunion/authpages/admins/bilansPedagogiques/indexEvent';
import IndexAds from '../components/tredunion/authpages/admins/ads';
import ManageAds from '../components/tredunion/authpages/admins/ads/manage';
import IndexAdsMembers from '../components/tredunion/authpages/members/IndexAdsMember';
import ShowAdMember from '../components/tredunion/authpages/members/ShowAdMember';
import IndexContracts from '../components/tredunion/authpages/admins/contracts';
import IndexTrash from '../components/tredunion/authpages/admins/trash';
import IndexEnvironmentalPolicies from '../components/tredunion/authpages/admins/companies/environmentalPolicies';
import IndexRepresentativeSectionTypes from '../components/tredunion/authpages/admins/companies/representativeSectionTypes';
import FillRequestManage from '../components/tredunion/frontpages/companies/FillRequestManage';
import IndexRoles from '../components/tredunion/authpages/admins/users/roles';
import IndexRole from '../components/tredunion/authpages/admins/users/roles';
import Contrats from '../components/tredunion/authpages/members/Contrats';
import IndexContracts2 from '../components/tredunion/authpages/admins/contracts/index2';
import IndexContracts3 from '../components/tredunion/authpages/admins/contracts/index3';
import MoteurRecherche from '../components/tredunion/frontpages/home/MoteurRecherche';
const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    //console.log('useeffect layout');
    //console.log(window.location.pathname);
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/iframe-transporteurs" element={<MoteurRecherche />} />

        <Route path="/adherents/:id" element={<CompanyShow />} />
        <Route path="/actualites" element={<PostIndex />} />
        <Route path="/actualites/:id" element={<PostShow />} />
        {/*<Route path="/logcoop" element={<Logcoop />} />*/}
        <Route path="/vis-ma-route" element={<VisMaRoute />} />
        <Route path="/le-transport-recrute" element={<LTR />} />
        <Route path="/le-monde-du-transport-reuni" element={<LMTR />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/politique-de-confidentialite" element={<RGPD />} />
        <Route path="/reponse-formulaire/:uuid" element={<SurveyForm />} />
        <Route path="/formulaire-entreprise/:uuid" element={<HomeLink />} />
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}
        <Route path="login" element={<LoginTred />} />
        <Route path="forgot-password" element={<ForgetPassword />} />
        <Route path="reset-password" element={<PasswordReset />} />

        {/*- ------------- Partners ---------------------------  */}
        <Route element={<AuthPartnerLayout />}>
          <Route path="/espace-partenaire" element={<HomePartners />} />
          <Route
            path="/espace-partenaire/informations"
            element={<InfosPerso />}
          />
        </Route>

        {/*- ------------- Membres ---------------------------  */}
        <Route element={<AuthMembreLayout isHome={true} />}>
          <Route path="/espace-membre/accueil" element={<HomeMembers />} />
        </Route>
        <Route element={<AuthMembreLayout />}>
          <Route path="/espace-membre/informations" element={<InfosPerso />} />
          <Route
            path="/espace-membre/transferer-un-fichier"
            element={<TransfertFichier />}
          />
          <Route
            path="/espace-membre/trouver-un-membre"
            element={<TrouverUnMembre />}
          />
          <Route
            path="/espace-membre/telecharger-annuaire-tred-union"
            element={<TelechargerAnnuaire />}
          />
          <Route
            path="/espace-membre/tred-ressources"
            element={<TredRessources />}
          />
          <Route path="/espace-membre/e-learning" element={<Elearning />} />
          <Route path="/espace-membre/visios" element={<Visios />} />
          <Route path="/espace-membre/codes-achats" element={<CodesAchats />} />
          <Route path="/espace-membre/factures" element={<Factures />} />
          <Route path="/espace-membre/contrats" element={<Contrats />} />
          <Route
            path="/espace-membre/presse-specialisee"
            element={<IndexTradePressMember />}
          />
          <Route
            path="/espace-membre/presse-specialisee/:id"
            element={<ShowTradePressMember />}
          />
          <Route path="/espace-membre/annonces" element={<IndexAdsMembers />} />
          <Route
            path="/espace-membre/annonces/:id"
            element={<ShowAdMember />}
          />
          <Route
            path="/espace-membre/mes-annonces"
            element={<IndexAds fromMembers={true} />}
          />
          <Route
            path="/espace-membre/mes-annonces/ajouter"
            element={<ManageAds fromMembers={true} />}
          />
          <Route
            path="/espace-membre/mes-annonces/:id/modifier"
            element={<ManageAds fromMembers={true} />}
          />
          <Route
            path="/espace-membre/bilans-pedagogiques/elearning"
            element={
              <IndexBilansPedagogiquesElearning
                fromMemberArea={true}
                theme={'elearning'}
              />
            }
          />
          <Route
            path="/espace-membre/bilans-pedagogiques/presentiel"
            element={
              <IndexBilansPedagogiquesEvent
                fromMemberArea={true}
                theme={'presentiel'}
              />
            }
          />
          <Route
            path="/espace-membre/bilans-pedagogiques/webinar"
            element={
              <IndexBilansPedagogiquesEvent
                fromMemberArea={true}
                theme={'webinar'}
              />
            }
          />
        </Route>

        {/*- ------------- Elearning : affichage d'une évaluation ---------------------------  */}
        <Route
          path="/evaluations-elearning/:uuid/print"
          element={<ShowEvaluation />}
        />

        {/*- ------------- Redirection ancien système ---------------------------  */}
        <Route path="/tred-ressources-dl" element={<OldResourceDl />} />

        {/*- ------------- Partage de fichiers : affichage d'un fichier ---------------------------  */}
        <Route path="/partage-fichiers/:uuid" element={<ShowSharedFile />} />

        {/*- ------------- Modification des données par les entreprises ---------------------------  */}
        <Route
          path="/edition-donnees-entreprise/:uuid"
          element={<FillRequestManage />}
        />

        {/*- ------------- Admin ---------------------------  */}
        <Route element={<AdminLayout />}>
          <Route path="/administration/calendrier" element={<IndexEvents />} />
          <Route
            path="/administration/formulaires-de-participation"
            element={<IndexSurveyParticipationForm />}
          />
          <Route
            path="/administration/formulaires-de-participation/:id"
            element={<ShowSurveyparticipationForm />}
          />
          <Route
            path="/administration/formulaires-de-participation/:id/modifier"
            element={<ManageSurveyParticipationForm />}
          />
          <Route
            path="/administration/evenements/ajouter"
            element={<ManageEvents />}
          />
          <Route
            path="/administration/evenements/:id/modifier"
            element={<ManageEvents />}
          />
          <Route
            path="/administration/evenements/:id"
            element={<DetailsEvents />}
          />
          <Route
            path="/administration/questionnaires"
            element={<IndexSurvey />}
          />
          <Route
            path="/administration/questionnaires/:id"
            element={<ShowSurvey />}
          />
          <Route
            path="/administration/questionnaires/ajouter"
            element={<ManageSurveys />}
          />
          <Route
            path="/administration/questionnaires/:id/modifier"
            element={<ManageSurveys />}
          />
          <Route
            path="/administration/declarations-achats"
            element={<IndexSurvey type={'purchase-declaration'} />}
          />
          <Route
            path="/administration/declarations-achats/:id"
            element={<ShowSurvey type={'purchase-declaration'} />}
          />
          <Route
            path="/administration/declarations-achats/ajouter"
            element={<ManagePurchaseDeclaration />}
          />
          <Route
            path="/administration/declarations-achats/:id/modifier"
            element={<ManagePurchaseDeclaration />}
          />
          {/*<Route
            path="/administration/contrats-factures-partenaires"
            element={<IndexContracts />}
          />*/}
          <Route
            path="/administration/contrats-factures-partenaires"
            element={<IndexContracts3 />}
          />
          <Route
            path="/administration/entreprises"
            element={<IndexCompanies />}
          />
          <Route
            path="/administration/entreprises/ajouter"
            element={<ManageCompanies />}
          />
          <Route
            path="/administration/entreprises/:id/modifier"
            element={<ManageCompanies />}
          />
          <Route
            path="/administration/entreprises-exports-excel"
            element={<IndexExportsExcel />}
          />
          <Route
            path="/administration/demarches-environnementales"
            element={<IndexEnvironmentalPolicies />}
          />
          <Route
            path="/administration/representative-section-types"
            element={<IndexRepresentativeSectionTypes />}
          />
          <Route path="/administration/membres/roles" element={<IndexRole />} />
          <Route path="/administration/membres" element={<IndexUsers />} />
          <Route
            path="/administration/membres/ajouter"
            element={<ManageUsers />}
          />
          <Route
            path="/administration/membres/:id/modifier"
            element={<ManageUsers />}
          />
          <Route
            path="/administration/partenaires"
            element={<IndexPartners />}
          />
          <Route
            path="/administration/partenaires/ajouter"
            element={<ManagePartners />}
          />
          <Route
            path="/administration/partenaires/:id/modifier"
            element={<ManagePartners />}
          />
          <Route path="/administration/actualites" element={<IndexPosts />} />
          <Route
            path="/administration/actualites/ajouter"
            element={<ManagePosts />}
          />
          <Route
            path="/administration/actualites/:id/modifier"
            element={<ManagePosts />}
          />
          <Route path="/administration/sliders" element={<IndexSliders />} />
          <Route
            path="/administration/sliders/ajouter"
            element={<ManageSlides />}
          />
          <Route
            path="/administration/sliders/:id/modifier"
            element={<ManageSlides />}
          />
          <Route path="/administration/visios" element={<IndexVisios />} />
          <Route
            path="/administration/visios/ajouter"
            element={<ManageVisios />}
          />
          <Route
            path="/administration/visios/:id/modifier"
            element={<ManageVisios />}
          />
          <Route
            path="/administration/vis-ma-route"
            element={<IndexVisMaRoute />}
          />
          <Route
            path="/administration/vis-ma-route/ajouter"
            element={<ManageVisMaRouteContent />}
          />
          <Route
            path="/administration/vis-ma-route/:id/modifier"
            element={<ManageVisMaRouteContent />}
          />
          <Route
            path="/administration/elearning-formations"
            element={<IndexElearningCourses />}
          />
          <Route
            path="/administration/elearning-formations/ajouter"
            element={<ManageCourses />}
          />
          <Route
            path="/administration/elearning-formations/:id/modifier"
            element={<ManageCourses />}
          />
          <Route
            path="/administration/elearning-activites"
            element={<IndexCourseLogs />}
          />
          <Route
            path="/administration/elearning-acces"
            element={<IndexCourseRequests />}
          />
          <Route
            path="/administration/elearning-acces-history"
            element={<IndexCourseRequestsHistory />}
          />
          <Route
            path="/administration/bilans-pedagogiques/elearning"
            element={<IndexBilansPedagogiquesElearning theme={'elearning'} />}
          />
          <Route
            path="/administration/bilans-pedagogiques/presentiel"
            element={<IndexBilansPedagogiquesEvent theme={'presentiel'} />}
          />
          <Route
            path="/administration/bilans-pedagogiques/webinar"
            element={<IndexBilansPedagogiquesEvent theme={'webinar'} />}
          />
          <Route
            path="/administration/partage-fichiers"
            element={<IndexSharedFiles />}
          />
          <Route path="/administration/corbeille" element={<IndexTrash />} />
          <Route path="/administration/journal" element={<IndexLogs />} />
          <Route path="/administration/fichiers" element={<IndexUploads />} />
          <Route path="/administration/factures" element={<IndexInvoices />} />
          <Route
            path="/administration/factures/ajouter"
            element={<ManageInvoices />}
          />
          <Route
            path="/administration/codes-achats"
            element={<IndexPurchaseCodes />}
          />
          <Route
            path="/administration/ressources"
            element={<IndexResources />}
          />
          <Route
            path="/administration/ressources/ajouter"
            element={<ManageResource />}
          />
          <Route
            path="/administration/ressources/:id/modifier"
            element={<ManageResource />}
          />
          <Route
            path="/administration/ressources/categories"
            element={<IndexResourcesCategories />}
          />
          <Route
            path="/administration/ressources/categories/ajouter"
            element={<ManageResource />}
          />
          <Route
            path="/administration/ressources/categories/:id/modifier"
            element={<ManageResource />}
          />
          <Route
            path="/administration/presse-specialisee"
            element={<IndexTradePress />}
          />{' '}
          <Route
            path="/administration/presse-specialisee/ajouter"
            element={<ManageTradePress />}
          />
          <Route
            path="/administration/presse-specialisee/:id/modifier"
            element={<ManageTradePress />}
          />
          <Route path="/administration/annonces" element={<IndexAds />} />
          <Route
            path="/administration/annonces/ajouter"
            element={<ManageAds />}
          />
          <Route
            path="/administration/annonces/:id/modifier"
            element={<ManageAds />}
          />
        </Route>

        <Route element={<AuthMembreElearningLayout />}>
          <Route
            path="/espace-membre/e-learning/:id"
            element={<ElearningCourse />}
          />
        </Route>

        <Route element={<AuthMembreElearningCongratulationLayout />}>
          <Route
            path="/espace-membre/e-learning-congratulation"
            element={<ElearningCongratulation />}
          />
        </Route>

        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
